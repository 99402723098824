import React from "react";

const DEFAULT_STROKE_COLOR = "#111827";

const FilterSvgIcon = ({ stroke = DEFAULT_STROKE_COLOR, ...props }) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99967 2.50004V0.833374M7.99967 2.50004C7.0792 2.50004 6.33301 3.24623 6.33301 4.16671C6.33301 5.08718 7.0792 5.83337 7.99967 5.83337M7.99967 2.50004C8.92015 2.50004 9.66634 3.24623 9.66634 4.16671C9.66634 5.08718 8.92015 5.83337 7.99967 5.83337M2.99967 12.5C3.92015 12.5 4.66634 11.7538 4.66634 10.8334C4.66634 9.9129 3.92015 9.16671 2.99967 9.16671M2.99967 12.5C2.0792 12.5 1.33301 11.7538 1.33301 10.8334C1.33301 9.9129 2.0792 9.16671 2.99967 9.16671M2.99967 12.5V14.1667M2.99967 9.16671V0.833374M7.99967 5.83337V14.1667M12.9997 12.5C13.9201 12.5 14.6663 11.7538 14.6663 10.8334C14.6663 9.9129 13.9201 9.16671 12.9997 9.16671M12.9997 12.5C12.0792 12.5 11.333 11.7538 11.333 10.8334C11.333 9.9129 12.0792 9.16671 12.9997 9.16671M12.9997 12.5V14.1667M12.9997 9.16671V0.833374"
        stroke={stroke}
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FilterSvgIcon;
