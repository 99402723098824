"use client";

import React, { useState } from "react";
import { Menu, User } from "react-feather";
import HeaderLogo from "@/assets/images/Franchise_logo_header.png";
import Image from "next/image";
import Link from "next/link";
import HeaderAutocomplete from "../HeaderAutocomplete";
import "../header.scss";
import MobileMenu from "./MobileMenu";
import useAuth from "@/hooks/useAuth";
import useAuthModal from "@/hooks/useAuthModal";
import ClientOnly from "@/components/common/ClientOnly";

const MobileHeader = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { isAuthenticated, user, profile } = useAuth();
  const { openAuthModal, showModal } = useAuthModal();
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const renderUserIcon = () => {
    if (isAuthenticated) {
      return (
        <Link href={"/profile"} prefetch={true}>
          <User size={30} />
        </Link>
      );
    } else {
      return (
        <button onClick={() => openAuthModal("sign-in")}>
          <User size={30} />
        </button>
      );
    }
  };

  return (
    <>
      <header className="mobile__header header__search  header-2 fixed left-0 right-0 top-0 z-50 bg-white shadow-lg">
        <div className="header__wrapper">
          <div className="header__menu">
            <button
              className="border-none bg-transparent outline-none"
              onClick={showDrawer}
              aria-label="Menu"
            >
              <Menu size={30} />
            </button>
          </div>
          {/* <div className="header__logo__wrapper">
            <Link href="/" className="flex items-center">
              <Image
                src={HeaderLogo}
                height={25}
                width={25}
                alt="logo"
                className="mobile__logo"
              />
            </Link>
          </div> */}
          <HeaderAutocomplete />
          <div className="header__login__wrapper">
            <Link href="/" className="flex items-center">
              <Image
                src={HeaderLogo}
                height={25}
                width={25}
                alt="logo"
                className="mobile__logo"
              />
            </Link>
          </div>
          {/* <div className="header__login__wrapper">
            <ClientOnly>{renderUserIcon()}</ClientOnly>
          </div> */}
        </div>
      </header>
      <MobileMenu onClose={closeDrawer} open={openDrawer} />
    </>
  );
};

export default MobileHeader;
