"use client";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setCategoriesAction,
  setCountriesAction,
  setConnectedBrandsAction,
  setAllCategoriesList,
} from "@/store/slices/miscSlice";
import { getAuthData } from "@/utils/authHelper";
import useAuth from "@/hooks/useAuth";
//import { setUser } from "@/store/slices/authSlice";

const HeaderWrapper = ({
  children,
  categories,
  countries,
  // connectedBrands,
  allCategoryList,
}) => {
  const dispatch = useDispatch();
  const { authLogin } = useAuth();

  useEffect(() => {
    dispatch(setCategoriesAction(categories));
    dispatch(setCountriesAction(countries));
    // dispatch(setConnectedBrandsAction(connectedBrands));
    if (allCategoryList) {
      dispatch(setAllCategoriesList(allCategoryList));
    }

    //console.log("HeaderWrapper getAuthData", getAuthData());
    //dispatch(setUser(getAuthData()));
    authLogin(getAuthData());
  }, []);

  return <>{children}</>;
};

export default HeaderWrapper;
