"use client";

import React, { useEffect, useMemo, useState } from "react";
import { Form } from "antd";
import { useRouter } from "next/navigation";
import { getCountryOptions } from "@/utils/helper";
import { useSelector } from "react-redux";
import SignInWithEmailForm from "./SignInWithEmailForm";
import AuthOtpVerify from "../AuthOtpVerify";
import { register } from "@/services/AuthServices";
import useAuthModal from "@/hooks/useAuthModal";

const SignInWithEmail = () => {
  const [error, setError] = useState({
    showAlert: false,
    message: null,
    type: "error",
  });
  const [loading, setLoading] = useState(false);
  const [signupData, setSignupData] = useState(null);
  const [showOtpPage, setShowOtpPage] = useState(false);

  const router = useRouter();
  const { countries } = useSelector((state) => state?.misc);
  const [form] = Form.useForm();
  const countryValue = Form.useWatch("countrycode", form);
  const { openAuthModal } = useAuthModal();
  const handleOnCountryChange = (value) => {
    console.log("value", value);
    const countrVl = countries?.find((el) => el.isoCode === value)?.isoCode;
    form.setFieldsValue({
      countrycode: countrVl,
    });
  };

  useEffect(() => {
    const countrVl = countries?.find((el) => el.isoCode === "IN")?.isoCode;
    form.setFieldsValue({
      countrycode: countrVl,
    });
  }, []);

  const openSignupPage = () => {
    openAuthModal("sign-up");
  };

  const countryOptions = useMemo(() => {
    return getCountryOptions(countries);
  }, [countries]);

  const onFinish = async (values) => {
    setLoading(true);
    let payload = {
      ...values,
      countrycode:
        countries?.find((el) => el.isoCode === values.countrycode)?.phoneCode ||
        "+91",
    };
    try {
      const response = await register(payload);
      setLoading(false);
      if (response?.status === "Success") {
        setSignupData(payload);
        setShowOtpPage(true);
      }
      if (response?.status === "Failed") {
        setError({
          showAlert: true,
          message: response?.message,
          type: "error",
        });
      }
    } catch (error) {
      setLoading(false);
      const message = error?.data?.error?.err_message?.toString() || "Failed!";
      setError({
        showAlert: true,
        message: message,
        type: "error",
      });
    }
  };
  // console.log("showOtpPage", showOtpPage);
  return (
    <>
      {showOtpPage && <AuthOtpVerify data={signupData} />}
      {!showOtpPage && (
        <SignInWithEmailForm
          form={form}
          onFinish={onFinish}
          error={error}
          handleOnCountryChange={handleOnCountryChange}
          openSignupPage={openSignupPage}
          countryOptions={countryOptions}
          countryValue={countryValue}
          loading={loading}
          orgCountry={countries}
        />
      )}
    </>
  );
};

export default SignInWithEmail;
