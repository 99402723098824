"use client";

import DesktopHeaderSearch from "./DesktopHeaderSearch";
import useResponsive from "@/hooks/useResponsive";
import Megamenu from "./Megamenu";

// import { getSeoMetaDataDetails } from "@/services/MetaDataServices";
// import { updateMetaDataForComponent } from "@/store/slices/metaDataSlice";
// import { useSelector, useDispatch } from "react-redux";

const DesktopHeader = ({ categories }) => {
  // const dispatch = useDispatch();
  // const metaData = useSelector((state) => state?.metaDataDetials);
  // console.log("metaDatametaData", metaData);

  const { isMobileOrTablet, isDesktop } = useResponsive();

  // const callSeoApi = async (ids, type) => {
  //   try {
  //     const params = {
  //       cat_type: type,
  //       cat_id: ids,
  //     };
  //     const response = await getSeoMetaDataDetails(params);
  //     if (response?.status === "Success") {
  //       const metaPayload = { ...response?.data };
  //       dispatch(updateMetaDataForComponent(metaPayload));

  //       console.log("responseresponse", response);
  //     }
  //   } catch (error) {
  //     console.log("eeeeeeeeee", error);
  //   }
  // };

  return (
    <header className="header-2 fixed left-0 right-0 top-0 z-50 bg-white shadow-lg">
      <DesktopHeaderSearch />
      <Megamenu categories={categories} />{" "}
    </header>
  );
};

export default DesktopHeader;
