export { default as ActivitySvgIcon } from "./ActivitySvgIcon";
export { default as HomeSvgIcon } from "./HomeSvgIcon";
export { default as PostYourRequirmentSvgIcon } from "./PostYourRequirmentSvgIcon";
export { default as WishlistSvgIcon } from "./WishlistSvgIcon";
export { default as MyOrdersSvgIcon } from "./MyOrdersSvgIcon";
export { default as ContactBrandsSvgIcon } from "./ContactBrandsSvgIcon";
export { default as MyProfileSvgIcon } from "./MyProfileSvgIcon";
export { default as BillingSvgIcon } from "./BillingSvgIcon";
export { default as LogoutSvgIcon } from "./LogoutSvgIcon";
export { default as SettingsSvgIcon } from "./SettingsSvgIcon";
export { default as PhoneSvgIcon } from "./PhoneSvgIcon";
export { default as FilterSvgIcon } from "./FilterSvgIcon";
export { default as InfoSvgIcon } from "./InfoSvgIcon";
export {default as BookverificationSvgIcon} from './BookverificationSvgIcon'
