"use client";

import React from "react";
import RestartIcon from "@/assets/images/restart_icon.svg";
import BrandSeachIcon from "@/assets/images/brand_search_icon.svg";
import Image from "next/image";

function SuggestionItem({
  suggestion,
  onSuggestionClick,
  isSelected,
  onKeyDown,
}) {
  return (
    <li
      onClick={() => onSuggestionClick(suggestion)}
      onKeyDown={onKeyDown}
      tabIndex={0}
      className={`suggestion__title ${isSelected ? "selected" : ""}`}
    >
      <Image
        src={suggestion?.in ? RestartIcon : BrandSeachIcon}
        className="suggestion__icon"
        alt="image"
      />
      <div className="flex flex-col">
        <p dangerouslySetInnerHTML={{ __html: suggestion?.text }} />
        {suggestion?.in && (
          <p className="label__name text-xs font-normal text-primary">
            in {suggestion?.in}{" "}
          </p>
        )}
      </div>
    </li>
  );
}

export default SuggestionItem;
