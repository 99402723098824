import React from "react";

const DEFAULT_STROKE_COLOR = "#111827";

const MyProfileSvgIcon = ({ stroke = DEFAULT_STROKE_COLOR, ...props }) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 5C12 7.20914 10.2091 9 8 9C5.79086 9 4 7.20914 4 5C4 2.79086 5.79086 1 8 1C10.2091 1 12 2.79086 12 5Z"
      stroke={stroke}
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 12C4.13401 12 1 15.134 1 19H15C15 15.134 11.866 12 8 12Z"
      stroke={stroke}
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default MyProfileSvgIcon;
