"use client";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import { Copy } from "react-feather";
import toast from "react-hot-toast";
import CallImage from "@/assets/images/Call--icon-Phone--Streamline-Core.svg";
import EmailImage from "@/assets/images/Email--Streamline-Ios-14.svg";
import WhatsappImage from "@/assets/images/Whatsapp--Streamline-Ios-14.svg";
import useResponsive from "@/hooks/useResponsive";

const HeaderHelpDropdown = () => {
  const { isDesktop } = useResponsive();
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied!");
  };

  return (
    <>
      <div className="z-10  divide-y">
        {/* <div className="border-b border-primary-border-color px-5 py-4">
          <div className="text-left">
            <p className="text-sm  font-normal text-color-secondary-dark ">
              Have questions or need assistance?
            </p>
            <p className="text-sm  font-normal text-color-secondary-dark ">
              Reach out to our support team for help!
            </p>
          </div>
        </div> */}
        <ul className="text-sm text-gray-700 dark:text-gray-200">
          <li>
            <Link
              href="#"
              className="flex items-center py-2 pl-3 pr-8 text-base font-medium text-color-black hover:bg-tertiary"
              prefetch={true}
            >
              <span className={`ml-3 text-base font-medium text-color-black`}>
                <Image src={CallImage} height={20} width={20} />
              </span>{" "}
              &nbsp; : 1800 2100 445 &nbsp;
              <Copy
                className="text-gray-500 hover:text-gray-800"
                height={15}
                width={15}
                onClick={() => {
                  handleCopy("1800 2100 445");
                }}
              />
            </Link>
          </li>

          <li>
            <Link
              href="#"
              className="flex items-center py-2 pl-3 pr-8 text-base font-medium text-color-black hover:bg-tertiary"
              prefetch={true}
            >
              <span className={`ml-3 text-base font-medium text-color-black`}>
                <Image src={EmailImage} height={20} width={20} />
              </span>
              &nbsp; : info@franchisediscovery.in &nbsp;
              <Copy
                className="text-gray-500 hover:text-gray-800"
                height={15}
                width={15}
                onClick={() => {
                  handleCopy("info@franchisediscovery.in");
                }}
              />
            </Link>
          </li>

          {isDesktop && (
            <li>
              <Link
                target="_blank"
                href="https://api.whatsapp.com/send?phone=918793465514"
                className="flex items-center py-2 pl-3 pr-8 text-base font-medium text-color-black hover:bg-tertiary"
                prefetch={true}
              >
                <span className={`ml-3 text-base font-medium text-color-black`}>
                  <Image src={WhatsappImage} height={20} width={20} />
                </span>
                &nbsp; :&nbsp;<a style={{ color: "blue" }}> Chat on Whatsapp</a>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default HeaderHelpDropdown;
