import React from "react";

const DEFAULT_STROKE_COLOR = "#111827";

const PostYourRequirmentSvgIcon = ({
  stroke = DEFAULT_STROKE_COLOR,
  ...props
}) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.70711 11.2929C5.31658 10.9024 4.68342 10.9024 4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071L5.70711 11.2929ZM7 14L6.29289 14.7071C6.68342 15.0976 7.31658 15.0976 7.70711 14.7071L7 14ZM11.7071 10.7071C12.0976 10.3166 12.0976 9.68342 11.7071 9.29289C11.3166 8.90237 10.6834 8.90237 10.2929 9.29289L11.7071 10.7071ZM14 5V17H16V5H14ZM13 18H3V20H13V18ZM2 17V5H0V17H2ZM3 4H5V2H3V4ZM11 4H13V2H11V4ZM3 18C2.44772 18 2 17.5523 2 17H0C0 18.6569 1.34315 20 3 20V18ZM14 17C14 17.5523 13.5523 18 13 18V20C14.6569 20 16 18.6569 16 17H14ZM16 5C16 3.34315 14.6569 2 13 2V4C13.5523 4 14 4.44772 14 5H16ZM2 5C2 4.44772 2.44772 4 3 4V2C1.34315 2 0 3.34315 0 5H2ZM4.29289 12.7071L6.29289 14.7071L7.70711 13.2929L5.70711 11.2929L4.29289 12.7071ZM7.70711 14.7071L11.7071 10.7071L10.2929 9.29289L6.29289 13.2929L7.70711 14.7071ZM7 2H9V0H7V2ZM9 4H7V6H9V4ZM7 4C6.44772 4 6 3.55228 6 3H4C4 4.65685 5.34315 6 7 6V4ZM10 3C10 3.55228 9.55228 4 9 4V6C10.6569 6 12 4.65685 12 3H10ZM9 2C9.55228 2 10 2.44772 10 3H12C12 1.34315 10.6569 0 9 0V2ZM7 0C5.34315 0 4 1.34315 4 3H6C6 2.44772 6.44772 2 7 2V0Z"
      fill={stroke}
    />
  </svg>
);

export default PostYourRequirmentSvgIcon;
