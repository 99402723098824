"use client";

import ClientOnly from "@/components/common/ClientOnly";
import React from "react";
import PostYourRequirment from "./PostYourRequirment";
import AntdModal from "@/components/common/Modal/AntdModal";
import usePyrModal from "@/hooks/usePyrModal";
import useResponsive from "@/hooks/useResponsive";

const PostYourRequirmentModal = () => {
  const { showPyrModal, closePyrModal } = usePyrModal();
  const { isMobile } = useResponsive();
  return (
    <AntdModal
      title={""}
      showModal={showPyrModal}
      handleOkModal={closePyrModal}
      handleCancelModal={closePyrModal}
      width={isMobile ? "90%" : 520}
      loading={false}
      footer={null}
      header={null}
      // className="mobile__filter-modal"
      // wrapClassName="mobile__filter-modal-wrapper"
      centered={false}
    >
      <ClientOnly>
        <h4 className="mb-2 text-xl font-semibold  text-color-primary">
          Post your requirement
        </h4>
        <div className="flex items-center justify-center lg:mx-0">
          <PostYourRequirment hideHeader={true} closePyrModal={closePyrModal} />
        </div>
      </ClientOnly>
    </AntdModal>
  );
};

export default PostYourRequirmentModal;
