"use client";

import React from "react";
import { Button, Input, Alert, Select, Form } from "antd";

const SignInWithEmailForm = ({
  form,
  onFinish,
  error,
  handleOnCountryChange,
  openSignupPage,
  countryOptions,
  countryValue,
  loading,
  orgCountry,
}) => {
  return (
    <div className="sign__page">
      <h5 className="mb-2 font-sans text-lg text-color-primary">Log In</h5>
      <p className="font-sans text-sm text-color-primary">
        Please enter your details
      </p>

      {error?.showAlert && (
        <div className="mt-2">
          <Alert message={error?.message} type={error?.type} showIcon />
        </div>
      )}

      <Form
        form={form}
        name="sign-in"
        onFinish={onFinish}
        initialValues={{
          countrycode: orgCountry?.find((el) => el.value === "IN")?.isoCode,
        }}
      >
        <div className="mb-0 mt-4">
          <Form.Item
            name="countrycode"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <div className="flex items-center">
              <span className="max-w-fit text-color-secondary-dark ">
                Your country is{" "}
              </span>
              <div className="ml-3  max-w-fit flex-1	">
                <Select
                  placeholder="Select a option and change input text above"
                  size="medium"
                  onChange={handleOnCountryChange}
                  defaultValue={"IN"}
                  options={countryOptions}
                  dropdownStyle={{ minWidth: "250px", zIndex: 1299 }}
                  placement="bottomLeft"
                  showSearch={true}
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                />
              </div>
            </div>
          </Form.Item>
          {countryValue === "IN" && (
            <Form.Item
              name="mobile"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <div className="mb-2 mt-3">
                <Input placeholder="Enter Phone no." size="large" />
              </div>
            </Form.Item>
          )}
          {countryValue !== "IN" && (
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <div className="mb-2 mt-3">
                <Input placeholder="Enter Email id." size="large" />
              </div>
            </Form.Item>
          )}
        </div>
        <div className="mb-1 flex flex-row items-center justify-start space-x-1 text-left text-sm font-medium text-gray-500">
          <p className="font-sans text-sm font-medium text-color-secondary-dark">
            New to Franchise Discovery?
          </p>{" "}
          <Button
            type="link"
            htmlType="button"
            block={false}
            size="large"
            className="flex flex-row items-center pl-1 text-sm font-semibold text-color-link"
            onClick={openSignupPage}
          >
            Join Now
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            htmlType="submit"
            block={true}
            size="large"
            className="mt-4 font-sans text-sm"
            //onClick={handleVerifyOtp}
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SignInWithEmailForm;
