import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDropdownData, getIndustryData } from "@/services/MiscServices";

export const fetchDropdownData = createAsyncThunk(
  "misc/fetchDropdownData",
  async () => {
    try {
      const response = await getDropdownData();
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchIndustryData = createAsyncThunk(
  "misc/fetchIndustryData",
  async () => {
    try {
      const response = await getIndustryData();
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
