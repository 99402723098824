"use client";
import React from "react";
import SuggestionItem from "./SuggestionItem";
import "./suggestion.scss";

function SuggestionList({
  suggestions,
  onSuggestionClick,
  selectedSuggestionIndex,
  onKeyDown,
}) {
  const renderSuggestionItem = () => {
    return suggestions.map((suggestion, index) => {
      if (suggestion?.suggestion__heading) {
        return (
          <li className="suggestion__heading" key={index}>
            {suggestion?.suggestion__heading}
          </li>
        );
      } else {
        return (
          <SuggestionItem
            key={index}
            suggestion={suggestion}
            onSuggestionClick={onSuggestionClick}
            isSelected={index === selectedSuggestionIndex}
            onKeyDown={onKeyDown}
          />
        );
      }
    });
  };
  return <>{renderSuggestionItem()}</>;
}

export default SuggestionList;
