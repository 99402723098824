"use client";
import { apiClient as commonClient } from "@/config/commonClient";

import { API_ENDPOINTS } from "@/constants/endpoints";

const { PAGE } = API_ENDPOINTS;

export const createContactus = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PAGE.saveContactus();
      const res = await commonClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const createFeedback = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PAGE.saveFeedback();
      const res = await commonClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const createNewsletter = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PAGE.saveNewsletter();
      const res = await commonClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
