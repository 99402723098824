"use client";

import React from "react";
import { Button, Form, Input } from "antd";
import * as ContactusService from "@/services/ContactusService";
import toast from "react-hot-toast";
const Newsletter = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log(values);

    const payload = {
      email: values?.username,
    };
    try {
      const response = await ContactusService.createNewsletter(payload);
      console.log("CREATE contact response", response);
      if (response.status === "Success") {
        toast.success("Newsletter subscribe sucessfully");
        form.resetFields();
      }
      if (response.status === "Failed") {
        toast.error(response?.error);
      }
    } catch (error) {
      console.log("error", error);
      //toast.error(error?.response?.message || "Something went wrong");
    } finally {
    }
  };

  return (
    <>
      <h2 className="mb-4 text-center text-lg lg:mb-8 lg:text-left lg:text-2xl lg:font-semibold">
        Subscribe Newsletter
      </h2>
      <Form
        form={form}
        name="horizontal_login"
        layout="inline"
        onFinish={onFinish}
        className="mb-3"
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
          className="flex-1"
        >
          <Input
            // prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Type your email"
            size="large"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block={true}
            size="large"
            className="ml-0 h-[39px]"
            //loading={loading}
          >
            Send
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Newsletter;
