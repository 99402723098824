import React from "react";

const DEFAULT_STROKE_COLOR = "#111827";

const BookverificationSvgIcon = ({
  stroke = DEFAULT_STROKE_COLOR,
  ...props
}) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 4H16C17.1046 4 18 4.89543 18 6V18C18 19.1046 17.1046 20 16 20H5C3.89543 20 3 19.1046 3 18V6C3 4.89543 3.89543 4 5 4Z"
      stroke="black"
      strokeWidth="2"
    />
    <path d="M3 8H18" stroke="black" strokeWidth="2" />
    <path d="M16 4V20" stroke="black" strokeWidth="2" />
    <path d="M21 16L23 18L26 15" stroke="black" strokeWidth="2" />
    <path
      d="M21 4H22C23.1046 4 24 4.89543 24 6V11C24 12.1046 23.1046 13 22 13H21"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export default BookverificationSvgIcon;
