"use client";

import { apiClient as brandClient } from "@/config/brandClient";
import { API_ENDPOINTS } from "@/constants/endpoints";

const { CATALOGUE, WISHLIST, CONATACT_BRAND } = API_ENDPOINTS;

export const getCatalogueList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.getCatalogueList();
      const res = await brandClient({
        url: url,
        method,
        params: params,
        showError: false,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCatalogueFilters = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.getCatalogueFilters();
      const res = await brandClient({ url: url, method, params: params });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCatalogueDetails = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.getCatalogueDetails();
      const res = await brandClient({
        url: url,
        method,
        params: params,
        showError: false,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCatalogueSuggestion = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.getCatalogueSuggestion();
      const res = await brandClient({ url: url, method, params: params });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
export const searchCatalogue = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.searchCatalogue();
      const res = await brandClient({ url: url, method, params: params });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

//Wishlist

export const getWishlistList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = WISHLIST.getWishlistList();
      const res = await brandClient({
        url: url,
        method,
        params: params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateWishlist = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = WISHLIST.updateWishlist();
      const res = await brandClient({
        url: url,
        method,
        data: {},
        params: params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

//Contact Brand
export const getContactBrandList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CONATACT_BRAND.getContactBrandList();
      const res = await brandClient({
        url: url,
        method,
        params: params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const directContactBrand = (catalogue_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CONATACT_BRAND.directContactBrand(catalogue_id);
      const res = await brandClient({ url: url, method, data: {} });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getContactDetails = (catalogue_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CONATACT_BRAND.getContactDetails(catalogue_id);
      const res = await brandClient({
        url: url,
        method,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCatalogueTopIndianList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.getCatalogueTopIndianList();
      const res = await brandClient({ url: url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCatalogueTopMaxInvestedList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.getCatalogueTopMaxInvestedList();
      const res = await brandClient({ url: url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

//Compate catalogue
export const postCatalogueCompare = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.postCatalogueCompare();
      const res = await brandClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
