// headerSearchSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clearSearchVal: false,
};

const headerSearchSlice = createSlice({
  name: "authModal",
  initialState,
  reducers: {
    clearAutoCompleteSearch(state, action) {
      state.clearSearchVal = action.payload;;
    },
  },
});

export const { clearSearchVal,clearAutoCompleteSearch } =
  headerSearchSlice.actions;

export default headerSearchSlice.reducer;
