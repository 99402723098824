"use client";
import useAuthModal from "@/hooks/useAuthModal";
import React from "react";
import AuthModal from "./AuthModal";
import ClientOnly from "@/components/common/ClientOnly";
import PostYourRequirmentModal from "../LandingPage/pyr/PostYourRequirmentModal";
import usePyrModal from "@/hooks/usePyrModal";

const AuthModalWrapper = () => {
  const { showModal } = useAuthModal();
  const { showPyrModal } = usePyrModal();

  return (
    <>
      {" "}
      {showModal && (
        <ClientOnly>
          <AuthModal />
        </ClientOnly>
      )}
      {showPyrModal && (
        <ClientOnly>
          <PostYourRequirmentModal />
        </ClientOnly>
      )}
    </>
  );
};

export default AuthModalWrapper;
