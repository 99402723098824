"use client";
import { memo } from "react";
import { Button, Popover } from "antd";
import HeaderLogo from "@/assets/images/Franchise_logo_header.png";
import Image from "next/image";
import { ChevronDown } from "react-feather";
import HeaderDropdown from "./HeaderDropdown";
import useAuth from "@/hooks/useAuth";
import ClientOnly from "@/components/common/ClientOnly";
import HeaderAutocomplete from "./HeaderAutocomplete";
import Link from "next/link";
import HeaderHelpDropdown from "./HeaderHelpDropdown";
import { useDispatch, useSelector } from "react-redux";
import { clearAutoCompleteSearch } from "@/store/slices/headerSearchSlice";

const DesktopHeaderSearch = memo(() => {
  const { isAuthenticated, user, profile } = useAuth();
  const dispatch = useDispatch();
  const { clearSearchVal } = useSelector((state) => state?.headerSearch);
  const showUserName = () => {
    if (isAuthenticated) {
      return (
        profile?.first_name || user?.first_name || user?.email || user?.mobile
      );
    } else {
      return "Sign In";
    }
  };

  return (
    <div className="header__search border-b border-primary-border-color">
      <div className="container mx-auto flex flex-wrap items-center justify-between  py-2.5">
        <Link href="/" className="flex items-center">
          <Image
            src={HeaderLogo}
            height={50}
            width={50}
            alt="logo"
            className="max-w-[37px]"
          />
          <span
            onClick={() => dispatch(clearAutoCompleteSearch(true))}
            className="ml-2 self-center whitespace-nowrap text-base font-semibold leading-5  text-color-danger"
          >
            Franchise <br />
            Discovery
          </span>
        </Link>

        <div className="flex items-center lg:order-2">
          <ClientOnly>
            <Popover
              content={<HeaderDropdown />}
              trigger="click"
              overlayClassName="header__popover"
            >
              <Button
                type="primary"
                size="large"
                icon={<ChevronDown />}
                className="icon__btn text-sm"
                title={showUserName()}
              >
                <span className="ellipsis">{showUserName()}</span>
              </Button>
            </Popover>
          </ClientOnly>

          <a className="ml-5 inline-flex cursor-pointer items-center justify-center text-sm font-semibold text-primary hover:underline">
            <ClientOnly>
              <Popover
                content={<HeaderHelpDropdown />}
                title={null}
                overlayClassName="header__popover"
              >
                Help
              </Popover>
            </ClientOnly>
          </a>
        </div>
        <div
          className="hidden w-full max-w-2xl flex-1 items-center justify-between lg:order-1 lg:flex lg:w-auto"
          id="mobile-menu-2"
        >
          <HeaderAutocomplete clearSearchVal={clearSearchVal} />
        </div>
      </div>
    </div>
  );
});

export default DesktopHeaderSearch;
