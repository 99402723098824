import React from "react";

const DEFAULT_STROKE_COLOR = "#111827";

const HomeSvgIcon = ({ stroke = DEFAULT_STROKE_COLOR, ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 10L3 8M3 8L10 1L17 8M3 8V18C3 18.5523 3.44772 19 4 19H7M17 8L19 10M17 8V18C17 18.5523 16.5523 19 16 19H13M7 19C7.55228 19 8 18.5523 8 18V14C8 13.4477 8.44772 13 9 13H11C11.5523 13 12 13.4477 12 14V18C12 18.5523 12.4477 19 13 19M7 19H13"
      stroke={stroke}
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default HomeSvgIcon;
