import { createSlice } from "@reduxjs/toolkit";
import { fetchDropdownData, fetchIndustryData } from "../thunks/miscThunks";

const initialState = {
  countries: [],
  categories: [],
  value: 1,
  dropdownData: {},
  industry: null,
  connectedBrands: 1,
  allCategoriesList: [],
};

export const miscSlice = createSlice({
  name: "misc",
  initialState: initialState,
  reducers: {
    setCountriesAction: (state, action) => {
      state.countries = action?.payload || [];
    },
    setCategoriesAction: (state, action) => {
      state.categories = action?.payload || [];
    },
    setConnectedBrandsAction: (state, action) => {
      state.connectedBrands = action?.payload || 1;
    },
    setDropdownDataAction: (state, action) => {
      state.dropdownData = action?.payload || {};
    },
    setIndustryDataAction: (state, action) => {
      state.industry = action?.payload || {};
    },
    setAllCategoriesList: (state, action) => {
      state.allCategoriesList = action?.payload || [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDropdownData.fulfilled, (state, action) => {
      state.loading = false;
      state.dropdownData = action.payload;
    });
    builder.addCase(fetchIndustryData.fulfilled, (state, action) => {
      state.loading = false;
      state.industry = action.payload;
    });
  },
});

// Action creators
export const {
  setCountriesAction,
  setCategoriesAction,
  setConnectedBrandsAction,
  setDropdownDataAction,
  setIndustryDataAction,
  setAllCategoriesList,
} = miscSlice.actions;

export default miscSlice.reducer;
