"use client";
import React, { useState } from "react";
import { Button } from "antd";
import { usePathname } from "next/navigation";
import useAuth from "@/hooks/useAuth";
import useAuthModal from "@/hooks/useAuthModal";
import Link from "next/link";

import {
  ActivitySvgIcon,
  BillingSvgIcon,
  ContactBrandsSvgIcon,
  HomeSvgIcon,
  LogoutSvgIcon,
  MyOrdersSvgIcon,
  MyProfileSvgIcon,
  PostYourRequirmentSvgIcon,
  WishlistSvgIcon,
  BookverificationSvgIcon,
} from "@/assets/Icons";
import usePyrModal from "@/hooks/usePyrModal";
import ClientOnly from "@/components/common/ClientOnly";
import BookAppointment from "@/features/LandingPage/pyr/BookAppointment";

const HeaderDropdown = () => {
  const { openAuthModal, showModal } = useAuthModal();
  const { isAuthenticated, authLogout, user } = useAuth();
  const pathname = usePathname();
  const { openPyrModal } = usePyrModal();
  const [showAppointermodal, setBookAppointeModalState] = useState(false);

  const defaultIconStrokeColor = "#686A70";
  const activeIconStrokeColor = "#1E7FD9";
  const iconSize = { width: 18, height: 18 };

  const menuItems = [
    { name: "Home", icon: HomeSvgIcon, url: "/", isUserLogged: true },
    {
      name: "All Category",
      icon: ActivitySvgIcon,
      url: "/franchisecategory",
      isUserLogged: false,
    },
    {
      name: "Post Your Requirement",
      icon: PostYourRequirmentSvgIcon,
      url: "",
      isUserLogged: false,
      onAction: () => {
        openPyrModal();
      },
      stopRouting: true,
    },
    {
      name: "Wishlist",
      icon: WishlistSvgIcon,
      url: "/wishlist",
      isUserLogged: false,
    },
    {
      name: "My Orders",
      icon: MyOrdersSvgIcon,
      url: "/my-orders",
      isUserLogged: false,
    },
    {
      name: "Contact Brands",
      icon: ContactBrandsSvgIcon,
      url: "/contact-brands",
      isUserLogged: false,
    },
    {
      name: "My Profile",
      icon: MyProfileSvgIcon,
      url: "/profile",
      isUserLogged: false,
    },
    {
      name: "Billing & Subscription",
      icon: BillingSvgIcon,
      url: "/subscription",
      isUserLogged: false,
    },
    {
      name: "Book Appointment",
      icon: BookverificationSvgIcon,
      url: "",
      isUserLogged: true,
      onAction: () => {
        setBookAppointeModalState(true);
      },
      stopRouting: true,
    },
    // { name: "Settings", icon: SettingsSvgIcon, url: "#", isUserLogged: true },
  ];

  const handleAuthButtonClick = (pageName) => {
    openAuthModal(pageName);
  };

  return (
    <>
      <div className={isAuthenticated ? "z-10 divide-y" : "z-10"}>
        {!isAuthenticated && (
          <div
            className={
              isAuthenticated
                ? " px-5 py-4"
                : "border-primary-border-color px-5 py-4"
            }
          >
            <div className="text-center">
              <Button
                type="primary"
                size="large"
                className="mb-4"
                onClick={() => handleAuthButtonClick("sign-in")}
              >
                Sign In
              </Button>
              <p className="text-sm  font-normal text-color-secondary-dark ">
                New to Franchise Discovery?{" "}
                <button
                  className="font-medium text-primary hover:underline"
                  onClick={() => handleAuthButtonClick("sign-up")}
                >
                  Join Now
                </button>
              </p>
            </div>
          </div>
        )}

        <ul className="text-sm text-gray-700 dark:text-gray-200">
          {menuItems.map((el, idx) => {
            const Icon = el.icon;
            const isActive = pathname === el.url;
            //const showMenu = el?.isUserLogged && user?.email;
            const showMenu = el?.isUserLogged && isAuthenticated;
            return !el?.isUserLogged ? (
              <li key={idx}>
                <Link
                  href={el?.stopRouting ? "" : el.url}
                  className="flex items-center py-2 pl-3 pr-8 text-base font-medium text-color-black hover:bg-tertiary"
                  prefetch={true}
                  onClick={el?.onAction ? el.onAction : () => {}}
                >
                  <Icon
                    stroke={
                      isActive ? activeIconStrokeColor : defaultIconStrokeColor
                    }
                    {...iconSize}
                  />
                  <span
                    className={`ml-3 text-base font-medium ${
                      isActive ? "text-primary" : "text-color-black"
                    }`}
                  >
                    {el.name}
                  </span>
                </Link>
              </li>
            ) : showMenu ? (
              <li key={idx}>
                <Link
                  href={el.url}
                  className="flex items-center py-2 pl-3 pr-8 text-base font-medium text-color-black hover:bg-tertiary"
                  prefetch={true}
                  onClick={el?.onAction ? el.onAction : () => {}}
                >
                  <Icon
                    stroke={
                      isActive ? activeIconStrokeColor : defaultIconStrokeColor
                    }
                    {...iconSize}
                  />
                  <span
                    className={`ml-3 text-base font-medium ${
                      isActive ? "text-primary" : "text-color-black"
                    }`}
                  >
                    {el.name}
                  </span>
                </Link>
              </li>
            ) : null;
          })}

          {isAuthenticated && (
            <li>
              <button
                className="flex w-full items-center py-2 pl-3 pr-8 text-base font-medium text-color-black hover:bg-tertiary"
                onClick={authLogout}
              >
                <LogoutSvgIcon stroke={defaultIconStrokeColor} {...iconSize} />
                <span className="ml-3 text-base font-medium text-color-black">
                  Logout
                </span>
              </button>
            </li>
          )}
        </ul>
      </div>
      {/* {showModal && <AuthModal />} */}
      <ClientOnly>
        {showAppointermodal && (
          <BookAppointment
            showModal={showAppointermodal}
            setShowModal={setBookAppointeModalState}
          />
        )}
      </ClientOnly>
    </>
  );
};

export default HeaderDropdown;

// const menuItems = [
//   {
//     name: "Home",
//     icon: HomeImage,
//     url: "#",
//   },

//   ...(isAuthenticated
//     ? [
//         {
//           name: "Post Your Requirement",
//           icon: PYRImage,
//           url: "#",
//         },
//         {
//           name: "Wishlist",
//           icon: WishlistImage,
//           url: "/wishlist",
//         },
//         {
//           name: "My Orders",
//           icon: MyordersImage,
//           url: "/my-orders",
//         },
//         {
//           name: "Contact Brands",
//           icon: MyordersImage,
//           url: "/contact-brands",
//         },
//         {
//           name: "My Profile",
//           icon: MyprofileImage,
//           url: "/profile",
//         },
//         {
//           name: "Recent Activity",
//           icon: ActivityImage,
//           url: "#",
//         },
//         {
//           name: "Billing & Subscription",
//           icon: BillingImage,
//           url: "/subscription",
//         },
//         {
//           name: "Settings",
//           icon: SettingsImage,
//           url: "#",
//         },
//       ]
//     : []),
// ];
