"use client";
import React from "react";
import Newsletter from "../Newsletter";
import FranchiseTopics from "./FranchiseTopics";
import Link from "next/link";
import Image from "next/image";
import { Instagram, Facebook, Linkedin } from "react-feather";
import usePyrModal from "@/hooks/usePyrModal";
import xLogo from "@/assets/images/xlogo.png";

const FOOTER_SECTIONS = [
  {
    title: "Help Center",
    links: [
      { href: "/user-guide", text: "User guide", target: "_blank" },
      { href: "/privacy-policy", text: "Privacy policy", target: "_blank" },
      { href: "/contact-us", text: "Contact us", target: "_self" },
      {
        href: "/cancellation-policy",
        text: "Cancellation & refund policy",
        target: "_blank",
      },
    ],
  },
  {
    title: "About FD",
    links: [
      { href: "/about", text: "About us", target: "_blank" },
      { href: "/feedback-page", text: "Feedback", target: "_blank" },
      {
        href: "/terms-and-condition",
        text: "Terms & Conditions",
        target: "_blank",
      },
      { href: "/sitemap.xml", text: "Sitemap", target: "_blank" },
    ],
  },
  {
    title: "Investor Franchise",
    links: [
      {
        href: process.env.NEXT_PUBLIC_BRAND_SIGNIN_URL,
        text: "Login Your Brand",
        target: "_blank",
      },
      {
        href: process.env.NEXT_PUBLIC_BRAND_SIGNUP_URL,
        text: "Register your Brand",
        target: "_blank",
      },
      {
        action: "openPyrModal",
        text: "Post your Requirement",
        href: "#",
        target: "_self",
      },
      {
        href: "/investor-learning-centre",
        text: "Investor Learning Center",
        target: "_blank",
      },
      {
        href: "/brand-learning-centre",
        text: "Brand Learning Center",
        target: "_blank",
      },
    ],
  },
];

const SOCIAL_LINKS = [
  {
    href: "https://www.facebook.com/franchisediscovery/",
    icon: Facebook,
    color: "#0165e1",
    alt: "Facebook",
  },
  {
    href: "https://twitter.com/fddiscovery?lang=en",
    icon: Image,
    alt: "Twitter",
  },
  {
    href: "https://www.instagram.com/franchisediscovery/",
    icon: Instagram,
    color: "#E1306C",
    alt: "Instagram",
  },
  {
    href: "https://in.linkedin.com/company/franchisediscov",
    icon: Linkedin,
    color: "#0a66c2",
    alt: "LinkedIn",
  },
];

const FooterLink = ({ href, action, text, onClick, target = "_blank" }) => {
  if (action) {
    return (
      <li className="text-left">
        <button
          onClick={onClick}
          className="text-left text-primary-dark hover:underline"
        >
          {text}
        </button>
      </li>
    );
  }
  return (
    <li className="text-left">
      <Link
        href={href}
        className="text-primary-dark hover:underline"
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
        prefetch={false}
      >
        {text}
      </Link>
    </li>
  );
};

const SocialLink = ({ href, icon: Icon, color, alt }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={alt}
    className="transition-transform hover:scale-110"
  >
    {Icon === Image ? (
      <Image src={xLogo} width={25} height={25} alt={alt} />
    ) : (
      <Icon style={{ color }} aria-hidden="true" />
    )}
  </a>
);

const DesktopFooter = () => {
  const { openPyrModal } = usePyrModal();

  const handleAction = (action) => {
    if (action === "openPyrModal") {
      openPyrModal();
    }
  };

  return (
    <footer className="bg-secondary pb-10 pt-2 md:pt-14">
      <div className="container mx-auto flex h-auto flex-col px-4 py-8">
        <div className="flex h-auto w-full flex-col lg:h-[380px] lg:flex-row lg:items-start lg:justify-between lg:py-0">
          <div className="lg:w-2/5">
            <FranchiseTopics />
          </div>
          <div className="lg:w-[55%]">
            <Newsletter />
            <div className="mt-8 flex flex-wrap justify-center gap-8 sm:justify-between lg:flex-nowrap">
              {FOOTER_SECTIONS.map((section) => (
                <div key={section.title} className="text-center sm:text-left">
                  <h2 className="mb-4 text-lg font-semibold">
                    {section.title}
                  </h2>
                  <ul className="space-y-2 text-[16px] font-medium">
                    {section.links.map((link) => (
                      <FooterLink
                        key={link.text}
                        href={link.href}
                        action={link.action}
                        text={link.text}
                        target={link.target}
                        onClick={() => link.action && handleAction(link.action)}
                      />
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center gap-4">
          <h2 className="text-lg font-semibold">Follow us:</h2>
          <div className="flex items-center gap-4">
            {SOCIAL_LINKS.map((link) => (
              <SocialLink key={link.alt} {...link} />
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default DesktopFooter;
