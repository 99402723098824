"use client";
import { useEffect, useState, useRef } from "react";
import { Button, Input } from "antd";
import SearchIcon from "@/assets/images/search_icon.svg";
import Image from "next/image";
import SuggestionList from "@/components/common/Suggestion/SuggestionList";
import { getCatalogueSuggestion } from "@/services/catalogueServices";
import useOutsideClick from "@/hooks/useOutsideClick";
import {
  createBrandDetailsPageSlug,
  createSlug,
  removeHtmlTags,
} from "@/utils/helper";
import { useRouter } from "next/navigation";
import { sessionHandler } from "@/utils/Storage";
import { useDispatch, useSelector } from "react-redux";
import { clearAutoCompleteSearch } from "@/store/slices/headerSearchSlice";

const HeaderAutocomplete = () => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);
  // const pathname = usePathname();
  // const searchParams = useSearchParams();

  const dispatch = useDispatch();

  const { clearSearchVal } = useSelector((state) => state?.headerSearch);

  const inputRef = useRef(null);
  useOutsideClick(inputRef, () => setShowSuggestions(false)); //Change my dropdown state to close when clicked outside
  const router = useRouter();

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(() => {
      return setTimeout(() => {
        fetchSuggestions(value);
      }, 300);
    });
  };
  const addTitleToBeginning = (arr, title) => [
    { suggestion__heading: title },
    ...arr,
  ];

  const fetchSuggestions = async (query) => {
    if (query) {
      let params = {
        search: query,
      };

      try {
        const response = await getCatalogueSuggestion(params);
        const resData = response.data;
        let withoutCategoryDummyData = [
          {
            keyword_id: "dummy",
            text: "dummy",
            master_cat_id: "dummy",
            cat_id: null,
          },
        ];
        let mergedDataArray = [];

        if (resData?.withCategory && resData?.withCategory.length > 0) {
          mergedDataArray = [
            ...mergedDataArray,
            ...addTitleToBeginning(resData?.withCategory, "With categories"),
          ];
        }
        if (resData?.brands && resData?.brands.length > 0) {
          mergedDataArray = [
            ...mergedDataArray,
            ...addTitleToBeginning(resData?.brands, "in brands"),
          ];
        }
        // if (resData?.withoutCategory && resData?.withoutCategory.length > 0) {
        //   mergedDataArray = [
        //     ...mergedDataArray,
        //     ...addTitleToBeginning(resData?.withoutCategory, "Others"),
        //   ];
        // }

        // if (withoutCategoryDummyData) {
        //   mergedDataArray = [
        //     ...mergedDataArray,
        //     ...addTitleToBeginning(withoutCategoryDummyData, "Others"),
        //   ];
        // }
        setSuggestions(mergedDataArray);
        setShowSuggestions(true);
      } catch (error) {}
    } else {
      setShowSuggestions(false);
    }
  };

  // const clearSearch = () => {
  //   setTimeout(() => {
  //     setQuery('');
  //   setSuggestions([]);

  //     // sessionHandler.set("searchQuery", '');
  //   }, 1000);
  // }

  const handleSuggestionClick = (suggestion) => {
    console.log("handleSuggestionClick", suggestion);
    if (!suggestion) return;
    setQuery(removeHtmlTags(suggestion?.text));
    sessionHandler.set("searchQuery", removeHtmlTags(suggestion?.text));
    setSuggestions([]);
    setShowSuggestions(false);
    if (suggestion?.catalogue_id) {
      return router.push(
        createBrandDetailsPageSlug(
          suggestion?.brandname,
          suggestion?.catalogue_id
        )
      );
    }

    // clearSearch()
    if (suggestion?.keyword_id) {
      return router.push(
        `/franchise?page=1&limit=20&keywordId=${suggestion?.keyword_id}`
      );
    }

    if (suggestion?.cat_id) {
      const pathName = createSlug(suggestion?.in);
      // const event = new CustomEvent("categorySelectedFromHeader");
      // window.dispatchEvent(event);
      router.push(
        `${pathName}?fsl=${suggestion?.master_cat_id}&fml=${suggestion?.cat_id}&page=1&limit=20`
      );
      // router.push(
      //   `/franchise?superCatId=${suggestion?.master_cat_id}&page=1&limit=20`
      // );
    }
    // setQuery('');

    //history.push(`/your-slug`); // Replace 'your-slug' with the actual slug
  };

  const handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      setShowSuggestions(false);
    }
  };
  useEffect(() => {
    const getSessionQuery = sessionHandler.get("searchQuery");
    if (getSessionQuery) {
      setQuery(getSessionQuery);
    }
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (query?.trim()?.length < 1) {
      return; // Prevent form submission if the query is empty
    }
    sessionHandler.set("searchQuery", query);
    // clearSearch()
    router.push(`/franchise?page=1&limit=20&search=${query}`);
  };

  const handleKeyDown = (e) => {
    console.log("caledd", e.key);
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();

        setSelectedSuggestionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        // setSelectedSuggestionIndex((prevIndex) =>
        //   prevIndex < suggestions?.withCategory.length - 1
        //     ? prevIndex + 1
        //     : prevIndex
        // );
        setSelectedSuggestionIndex((prevIndex) => prevIndex + 1);
        break;
      case "Enter":
        e.preventDefault();
        if (selectedSuggestionIndex > 0) {
          if (suggestions[selectedSuggestionIndex]) {
            handleSuggestionClick(suggestions[selectedSuggestionIndex]);
          }
        } else {
          handleSubmitForm(e);
        }
        break;
      default:
        // Handle other key presses if needed
        break;
    }
  };

  useEffect(() => {
    if (clearSearchVal) {
      setQuery("");
      setSuggestions([]);
      dispatch(clearAutoCompleteSearch(false));
      sessionHandler.set("searchQuery", "");
    }
  }, [clearSearchVal]);

  const renderSuggestions = () => {
    return (
      <>
        {suggestions && suggestions.length > 0 && (
          <SuggestionList
            suggestions={suggestions || []}
            onSuggestionClick={handleSuggestionClick}
            selectedSuggestionIndex={selectedSuggestionIndex}
          />
        )}
      </>
    );
  };
  return (
    <div className="suggestions__wrapper flex w-full">
      <form ref={inputRef} onSubmit={handleSubmitForm}>
        <div className="search__input-wrapper">
          <div className="search__input">
            <Input
              // prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Search Category or Products"
              size="large"
              className="header__search-input"
              onChange={handleSearchInputChange}
              value={query}
              onFocus={() => setShowSuggestions(true)}
              onKeyDown={handleKeyDown}
              aria-label="Search Category or Products"
            />
          </div>
          <Button
            type="primary"
            htmlType="submit"
            block={false}
            size="large"
            className="header__search-btn ml-0 h-[39px] w-[80px]"
            disabled={query.length < 1}
            icon={
              <Image
                src={SearchIcon}
                height={20}
                width={20}
                alt="search icon"
                className="w-6"
              />
            }
            //loading={loading}
          ></Button>
        </div>
        {showSuggestions && suggestions?.length > 0 && (
          <ul className="suggestions">{renderSuggestions()}</ul>
        )}
      </form>
    </div>
  );
};

export default HeaderAutocomplete;
