"use client";
import React from "react";
import AntdModal from "@/components/common/Modal/AntdModal";
import SignUp from "./Signup/SignUp";
import SignIn from "./SignIn/SignIn";
import useAuthModal from "@/hooks/useAuthModal";
import "./auth.scss";

const AuthModal = () => {
  const { pageName, showModal, closeAuthModal } = useAuthModal();

  return (
    <AntdModal
      showModal={showModal}
      handleCancelModal={closeAuthModal}
      footer={null}
      modalTitle={null}
      className="auth_modal"
    >
      {pageName === "sign-in" && <SignIn />}
      {pageName === "sign-up" && <SignUp />}
    </AntdModal>
  );
};

export default AuthModal;
