import React from "react";

const DEFAULT_STROKE_COLOR = "#111827";

const InfoSvgIcon = ({ stroke = DEFAULT_STROKE_COLOR, ...props }) => (
    <svg
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        {...props}
    >
        <path 
            d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12s12-5.373 12-12S18.627 0 12 0zm0 1.5c5.799 0 10.5 4.701 10.5 10.5s-4.701 10.5-10.5 10.5S1.5 17.299 1.5 12S6.201 1.5 12 1.5zM11 5v2h2V5h-2zm1 4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1s1-.45 1-1v-8c0-.55-.45-1-1-1z"
            fill="currentColor" 
        />
    </svg>
);

export default InfoSvgIcon;
