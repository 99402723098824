"use client";

import React from "react";
import Link from "next/link";
import Image from "next/image";
import FoodBeverages from "@/assets/images/food_beverages.png";
import RetailShop from "@/assets/images/retail_shop.png";
import HealthCare from "@/assets/images/health_care.png";
import Logistics from "@/assets/images/logistics.png";
import Education from "@/assets/images/education.png";
import Tours from "@/assets/images/tours.png";
import { createSlug, createSlugFromWord } from "@/utils/helper";
import { useState } from "react";
import { usePathname } from "next/navigation";
import { Eye } from "react-feather";
import { clearAutoCompleteSearch } from "@/store/slices/headerSearchSlice";
import { useDispatch, useSelector } from "react-redux";

let superCategoryImages = {
  "Food & Beverages": FoodBeverages,
  "Retail Shop": RetailShop,
  Retail: RetailShop,
  Logistics: Logistics,
  Healthcare: HealthCare,
  Education: Education,
  "Tours & Leisure": Tours,
  "Information Technology": Tours,
};

const Megamenu = ({ categories }) => {
  const [megaMenuVisible, setMegaMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const { clearSearchVal } = useSelector((state) => state?.headerSearch);
  const pageParams = {
    page: 1,
    limit: 20,
  };

  const pathname = usePathname();

  const hideViewAllCategoryButton =
    pathname === "/franchisecategory" ? true : false;
  return (
    <div className="hidden border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-900 md:block">
      <div className="container relative mx-auto  flex">
        <div className="flex-wrap items-center justify-between py-0">
          <div
            id="mega-menu-full"
            className="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto "
          >
            <ul className="mt-4 flex flex-col font-medium md:mt-0 md:flex-row md:space-x-8">
              {categories?.map((sup_category, idx) => (
                <li
                  className="hoverable"
                  key={idx}
                  onMouseEnter={() => setMegaMenuVisible(true)}
                  onMouseLeave={() => setMegaMenuVisible(false)}
                  onClick={() => dispatch(clearAutoCompleteSearch(true))}
                >
                  <Link
                    prefetch={true}
                    href={{
                      pathname: `${createSlug(`${sup_category?.name}`)}`,
                      query: {
                        fsl: sup_category?._id,
                        page: pageParams?.page,
                        limit: pageParams?.limit,
                        type: "master",
                      },
                    }}
                    className="flex items-center  border-b-[4px] border-transparent py-2 pl-0 pr-1 text-color-black hover:border-current hover:border-danger"
                    onClick={() => {
                      setMegaMenuVisible(false);
                      // callSeoApi(sup_category?._id, "master");
                    }}
                  >
                    <Image
                      src={superCategoryImages[sup_category?.name]}
                      height={30}
                      width={30}
                      alt="image"
                    />
                    <p className="ml-2 pt-1  align-middle  text-base font-medium ">
                      {sup_category?.name}
                    </p>
                  </Link>
                  {megaMenuVisible && (
                    <div className="mega-menu  bg-white shadow-sm dark:border-gray-600 dark:bg-gray-800">
                      <div className="mx-auto  flex px-4 py-5 text-gray-900 dark:text-white  md:px-6">
                        <div
                          aria-labelledby="mega-menu-full-dropdown-button"
                          className="mega-menu-ul"
                        >
                          {sup_category?.categories?.map((category, idxx) => (
                            <div
                              className="mb-4 px-6"
                              key={idxx}
                              // onClick={() => callSeoApi(category?._id, "cat")}
                              onClick={() =>
                                dispatch(clearAutoCompleteSearch(true))
                              }
                            >
                              <Link
                                prefetch={true}
                                href={{
                                  pathname: `${createSlug(
                                    `${category?.name}`
                                  )}`,
                                  query: {
                                    fsl: sup_category?._id,
                                    fml: category?._id,
                                    page: pageParams?.page,
                                    limit: pageParams?.limit,
                                    type: "cat",
                                  },
                                }}
                                className="mb-2 px-1 text-base font-semibold text-color-danger"
                                onClick={() => {
                                  setMegaMenuVisible(false);
                                  // callSeoApi(category?._id, "cat");
                                }}
                              >
                                {category?.name}
                              </Link>
                              {category?.subcategories?.map(
                                (subcategory, idxxx) => (
                                  <Link
                                    prefetch={true}
                                    href={{
                                      pathname: `${createSlug(
                                        `${subcategory?.name}`
                                      )}`,
                                      query: {
                                        fsl: sup_category?._id,
                                        fml: category?._id,
                                        fnsl: subcategory?._id,
                                        page: pageParams?.page,
                                        limit: pageParams?.limit,
                                        type: "subcat",
                                      },
                                    }}
                                    className="block rounded-lg px-1 py-[3px] font-normal leading-4 text-color-secondary-dark hover:text-color-danger"
                                    key={idxxx}
                                    onClick={() => {
                                      setMegaMenuVisible(false);
                                      // callSeoApi(category?._id, "subcat");
                                    }}
                                  >
                                    <span className="text-sm">
                                      {subcategory?.name}
                                    </span>
                                  </Link>
                                )
                              )}

                              {!hideViewAllCategoryButton &&
                                category?.subcategories.length >= 6 && (
                                  <div
                                    className=" underlinehover:underline inline-flex items-center justify-center pl-1 text-sm font-medium  text-primary "
                                    onClick={() =>
                                      dispatch(clearAutoCompleteSearch(true))
                                    }
                                  >
                                    <Link
                                      href={`/franchisecategory#${createSlugFromWord(
                                        sup_category?.name
                                      )}`}
                                      prefetch={false}
                                    >
                                      View all
                                    </Link>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              ))}

              {!hideViewAllCategoryButton && (
                <li
                  onClick={() => dispatch(clearAutoCompleteSearch(true))}
                  className=" inline-flex items-center justify-center text-sm font-medium text-primary hover:underline"
                >
                  <Link
                    href="/franchisecategory"
                    className="flex items-center gap-1 "
                    prefetch={true}
                  >
                    <Eye size={20} />
                    View all
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Megamenu;
