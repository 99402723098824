"use client";
import React, { useState } from "react";
import { Drawer, Popover } from "antd";
import Link from "next/link";
import Image from "next/image";
import useAuth from "@/hooks/useAuth";
import useAuthModal from "@/hooks/useAuthModal";
import {
  ActivitySvgIcon,
  BillingSvgIcon,
  ContactBrandsSvgIcon,
  HomeSvgIcon,
  LogoutSvgIcon,
  MyOrdersSvgIcon,
  MyProfileSvgIcon,
  PostYourRequirmentSvgIcon,
  SettingsSvgIcon,
  WishlistSvgIcon,
  PhoneSvgIcon,
  BookverificationSvgIcon,
} from "@/assets/Icons";
import { usePathname } from "next/navigation";
import usePyrModal from "@/hooks/usePyrModal";
import HeaderHelpDropdown from "../HeaderHelpDropdown";
import BookAppointment from "@/features/LandingPage/pyr/BookAppointment";
import ClientOnly from "@/components/common/ClientOnly";
import WhatsappImage from "@/assets/images/Whatsapp--Streamline-Ios-14.svg";

const MobileMenu = ({ onClose, open }) => {
  const { isAuthenticated, user, profile, authLogout } = useAuth();
  const { openAuthModal } = useAuthModal();
  const pathname = usePathname();
  const { openPyrModal, showPyrModal } = usePyrModal();
  const [showAppointermodal, setBookAppointeModalState] = useState(false);

  const defaultIconStrokeColor = "#686A70";
  const activeIconStrokeColor = "#1E7FD9";
  const iconSize = { width: 18, height: 18 };

  const menuItems = [
    { name: "Home", icon: HomeSvgIcon, url: "/", isUserLogged: false },
    {
      name: "All Category",
      icon: ActivitySvgIcon,
      url: "/franchisecategory",
      isUserLogged: false,
    },
    {
      name: "Post Your Requirement",
      icon: PostYourRequirmentSvgIcon,
      url: "#",
      onAction: () => {
        openPyrModal();
        onClose();
      },
      isUserLogged: false,
      //onAction: () => console.log("clicked"),
    },
    {
      name: "Wishlist",
      icon: WishlistSvgIcon,
      url: "/wishlist",
      isUserLogged: false,
    },
    {
      name: "My Orders",
      icon: MyOrdersSvgIcon,
      url: "/my-orders",
      isUserLogged: false,
    },
    {
      name: "Contact Brands",
      icon: ContactBrandsSvgIcon,
      url: "/contact-brands",
      isUserLogged: false,
    },
    {
      name: "My Profile",
      icon: MyProfileSvgIcon,
      url: "/profile",
      isUserLogged: false,
    },
    {
      name: "Billing & Subscription",
      icon: BillingSvgIcon,
      url: "/subscription",
      isUserLogged: false,
    },
    {
      name: "Book Appointment",
      icon: BookverificationSvgIcon,
      url: "",
      isUserLogged: true,
      onAction: () => {
        setBookAppointeModalState(true);
      },
      stopRouting: true,
    },
  ];

  const showUserName = () => {
    if (isAuthenticated) {
      return profile?.first_name || user?.email || user?.mobile;
    }
  };

  const renderUserName = () => {
    if (isAuthenticated) {
      return (
        <>
          <span className="text-base font-medium text-color-primary-grey">
            Hi,
          </span>
          <span className="ml-2 text-base font-medium text-primary">
            {showUserName()}
          </span>
        </>
      );
    } else {
      return (
        <p className="text-sm font-normal text-color-secondary-dark">
          New to Franchise Discovery?{" "}
          <button
            className="ml-2 font-medium text-primary hover:underline"
            onClick={() => {
              openAuthModal("sign-in");
              onClose();
            }}
          >
            Join Now
          </button>
        </p>
      );
    }
  };

  return (
    <>
      <Drawer
        onClose={onClose}
        open={open}
        placement="left"
        closable={false}
        className="mobile__drawer__body"
        width={"80%"}
        rootClassName="mobile__drawer"
      >
        <div className="sidebar__container h-full">
          <div className="sidebar__nav flex h-full flex-col">
            <div className="sidebar__header bg-secondary px-3 py-3">
              <div className="flex">{renderUserName()}</div>
            </div>
            <ul className="text-sm text-gray-700 dark:text-gray-200">
              {menuItems.map((item, idx) => {
                const Icon = item.icon;
                const isActive = pathname === item.url;
                const showMenu = item?.isUserLogged && isAuthenticated;
                return !item?.isUserLogged ? (
                  <li key={idx}>
                    <Link
                      href={item?.stopRouting ? "" : item.url}
                      className="flex items-center px-3 py-2 text-base font-medium text-color-black hover:bg-tertiary"
                      prefetch={true}
                      onClick={item?.onAction ? item.onAction : () => {}}
                    >
                      <Icon
                        stroke={
                          isActive
                            ? activeIconStrokeColor
                            : defaultIconStrokeColor
                        }
                        {...iconSize}
                      />
                      <span
                        className={`ml-3 text-base font-medium ${
                          isActive ? "text-primary" : "text-color-black"
                        }`}
                      >
                        {item.name}
                      </span>
                    </Link>
                  </li>
                ) : showMenu ? (
                  <li key={idx}>
                    <Link
                      href={item?.stopRouting ? "" : item.url}
                      className="flex items-center px-3 py-2 text-base font-medium text-color-black hover:bg-tertiary"
                      prefetch={true}
                      onClick={item?.onAction ? item.onAction : () => {}}
                    >
                      <Icon
                        stroke={
                          isActive
                            ? activeIconStrokeColor
                            : defaultIconStrokeColor
                        }
                        {...iconSize}
                      />
                      <span
                        className={`ml-3 text-base font-medium ${
                          isActive ? "text-primary" : "text-color-black"
                        }`}
                      >
                        {item.name}
                      </span>
                    </Link>
                  </li>
                ) : null;
              })}
            </ul>

            <ul className="mb-5 mt-auto text-sm text-gray-700 dark:text-gray-200">
              <li>
                <Link
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=918793465514"
                  className="flex items-center py-2 pl-3 pr-8 text-base font-medium text-color-black hover:bg-tertiary"
                  prefetch={false}
                >
                  <span
                    className={`ml-0 text-base font-medium text-color-black`}
                  >
                    <Image src={WhatsappImage} height={20} width={20} />
                  </span>
                  &nbsp; :&nbsp;
                  <a style={{ color: "blue" }}> Chat on Whatsapp</a>
                </Link>
              </li>

              <li>
                <Popover
                  content={<HeaderHelpDropdown />}
                  title={null}
                  overlayClassName="header__popover"
                >
                  <button className="flex w-full items-center py-2 pl-3 pr-8 text-base font-medium text-color-black hover:bg-tertiary">
                    <PhoneSvgIcon
                      stroke={defaultIconStrokeColor}
                      {...iconSize}
                    />
                    <span className="ml-3 text-base font-medium text-color-black">
                      Help & Support
                    </span>
                  </button>
                </Popover>
              </li>
              {isAuthenticated && (
                <li>
                  <button
                    className="flex w-full items-center py-2 pl-3 pr-8 text-base font-medium text-color-black hover:bg-tertiary"
                    onClick={authLogout}
                  >
                    <LogoutSvgIcon
                      stroke={defaultIconStrokeColor}
                      {...iconSize}
                    />
                    <span className="ml-3 text-base font-medium text-color-black">
                      Logout
                    </span>
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Drawer>

      <ClientOnly>
        {showAppointermodal && (
          <BookAppointment
            showModal={showAppointermodal}
            setShowModal={setBookAppointeModalState}
          />
        )}
      </ClientOnly>
    </>
  );
};

export default MobileMenu;
