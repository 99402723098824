"use client";

import React, { useMemo, useState } from "react";
import { Form } from "antd";
import { getCountryOptionsWithPhoneCode } from "@/utils/helper";
import { useSelector } from "react-redux";
import { register } from "@/services/AuthServices";
import AuthOtpVerify from "../AuthOtpVerify";
import SignupForm from "./SignupForm";
import useAuthModal from "@/hooks/useAuthModal";

const SignUp = () => {
  const [error, setError] = useState({
    showAlert: false,
    message: null,
    type: "error",
  });
  const [loading, setLoading] = useState(false);
  const [signupData, setSignupData] = useState(null);
  const [showOtpPage, setShowOtpPage] = useState(false);

  const { countries } = useSelector((state) => state?.misc);
  const [form] = Form.useForm();
  const countryValue = Form.useWatch("countrycode", form);
  const { openAuthModal } = useAuthModal();
  const handleOnCountryChange = (value) => {
    console.log("value", value);
    form.setFieldsValue({
      countrycode: value,
    });
  };

  const openSignupPage = () => {
    openAuthModal("sign-in");
  };

  const countryOptions = useMemo(() => {
    return getCountryOptionsWithPhoneCode(countries);
  }, [countries]);

  const onFinish = async (values) => {
    setLoading(true);
    console.log(values);
    let payload = {
      ...values,
    };
    try {
      const response = await register(payload);
      setLoading(false);
      console.log("response", response);
      if (response?.status === "Success") {
        setSignupData(values);
        setShowOtpPage(true);
      }
      if (response?.status === "Failed") {
        setError({
          showAlert: true,
          message: response?.message,
          type: "error",
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {showOtpPage ? (
        <AuthOtpVerify data={signupData} />
      ) : (
        <SignupForm
          form={form}
          onFinish={onFinish}
          error={error}
          handleOnCountryChange={handleOnCountryChange}
          openSignupPage={openSignupPage}
          countryOptions={countryOptions}
          countryValue={countryValue}
          loading={loading}
        />
      )}
    </>
  );
};

export default SignUp;
