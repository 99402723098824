"use client";

import React, { useState } from "react";
import { Button, Input, Alert } from "antd";
import { verifyOtp, resendOtp } from "@/services/AuthServices";
import { toast } from "react-hot-toast";
import useAuth from "@/hooks/useAuth";
import { useRouter } from "next/navigation";
import { useDispatch } from "react-redux";

import * as PYRServices from "@/services/PYRServices";
import { setInvestorData } from "@/store/slices/authInvestoreData";
import { getProfileData } from "@/store/thunks/profileThunks";

const AuthOtpVerify = ({ data }) => {
  const { authLogin } = useAuth();
  const dispatch = useDispatch();

  console.log("data", data);
  const [error, setError] = useState({
    showAlert: false,
    message: null,
    type: "error",
  });
  const [otpInput, setOtpInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [otpData, setOtpData] = useState(data);
  const router = useRouter();

  const handleVerifyOtp = async () => {
    setLoading(true);
    let payload = {
      email: otpData?.email || undefined,
      mobile: +otpData?.mobile || undefined,
      otp: +otpInput,
    };
    try {
      const response = await verifyOtp(payload);
      setLoading(false);
      setOtpInput("");
      if (response?.status === "Success") {
        console.log("successsssssssssssssssss", response);
        //dispatch(setUser(response?.data));
        authLogin(response?.data);
        dispatch(getProfileData());
        //setAuthData(response?.data);
        toast.success(response?.message);
        if (payload?.email) {
          const payloads = {
            search: payload.email || undefined,
          };
          const response = await PYRServices.findInvestor(payloads);
          dispatch(setInvestorData(response?.data));
        }

        const closeBtn = document.querySelector(".auth_modal .ant-modal-close");
        //router.push("/profile");
        router.refresh();
        setTimeout(() => {
          window.location.reload();
        }, [500]);

        if (closeBtn) {
          closeBtn.click();
        }
      }
      if (response?.status === "Failed") {
        setError({
          showAlert: true,
          message: response?.message,
          type: "error",
        });
      }
    } catch (error) {
      console.log("errorerror", error);
      setLoading(false);
      setOtpInput("");
      const message = error?.data?.error?.err_message?.toString() || "Failed!";
      setError({
        showAlert: true,
        message: message,
        type: "error",
      });
    }
  };

  const handeResendOtp = async () => {
    setResending(true);
    let payload = {
      email: otpData?.email || undefined,
      mobile: otpData?.mobile?.toString() || undefined,
      countrycode: otpData?.countrycode || "+91",
    };

    try {
      const response = await resendOtp(payload);
      console.log("resendOtp", response);
      if (response?.status === "Success") {
        setError({
          showAlert: true,
          type: "success",
          message: response.message,
        });
      }
      if (response?.status === "Failed") {
        setError({
          showAlert: true,
          message: response?.message,
          type: "error",
        });
      }
    } catch (error) {
      const message = error?.data?.error?.err_message?.toString() || "Failed!";
      setError({
        showAlert: true,
        message: message,
        type: "error",
      });
    } finally {
      setResending(false);
    }
  };
console.log("otpDataotpData",otpData)
  const otpSentToText = () => {
    if (otpData?.mobile) {
      return (
        <span>
          mobile no. <b>{otpData?.mobile}</b>
        </span>
      );
    }
    return (
      <span>
        email id. <b>{otpData?.email}</b>
      </span>
    );
  };

  return (
    <div className="pyrotp_verify">
      <h5 className="mb-2 font-sans text-lg text-color-primary">
        OTP Verification
      </h5>
      <p className="font-sans text-sm text-color-primary-dark">
        Enter OTP which we sent to your {otpSentToText()}
      </p>

      {error?.showAlert && (
        <div className="mt-2">
          <Alert message={error?.message} type={error?.type} showIcon />
        </div>
      )}

      <div className="mb-2 mt-4">
        <Input
          placeholder="Enter OTP"
          size="large"
          onChange={(e) => setOtpInput(e.target.value)}
          value={otpInput}
        />
      </div>
      <div className="mb-4 flex flex-row items-center justify-start space-x-1 text-left text-sm font-medium text-gray-500">
        <p className="font-sans text-sm font-medium text-color-secondary-dark">
          Didn't recieve code?
        </p>{" "}
        <Button
          type="link"
          htmlType="button"
          block={false}
          size="large"
          className="flex flex-row items-center pl-1 text-sm font-semibold text-color-link"
          onClick={handeResendOtp}
          loading={resending}
        >
          {resending ? "Sending..." : "Resend OTP"}
        </Button>
      </div>

      <div>
        <Button
          type="primary"
          htmlType="submit"
          block={true}
          size="large"
          className="mt-4 font-sans text-sm"
          onClick={handleVerifyOtp}
          loading={loading}
        >
          Submit OTP
        </Button>
      </div>
    </div>
  );
};

export default AuthOtpVerify;
